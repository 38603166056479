<template>
    <div class="container">
      <tab-header :headerList="boss.anaylsisList" :currentTab="1" @changeTabPage="changeTabPage"></tab-header>
      <!--时间选择-->
      <time-select @timeChange="timeChange"></time-select>
      <div class="scroll-box">
          <div class="main-content">
              <!--7日营业走势图-->
              <analysis-chart :chartParams="chartParams" :timeParams="timeParams"></analysis-chart>
              <!--同步时间-->
              <synchronize-time></synchronize-time>
              <!--营业数据-->
             <revenue-detail :data="revenueSummary"
                              :chartParams="chartParams"
                              :timeType="timeParams.type"
                              @changeChartDate="changeChartDate"
                              ></revenue-detail>
          </div>
          <daqi-copyright></daqi-copyright>
      </div>
    </div>
</template>

<script>
import table from '@/api/table'
import {mapState} from 'vuex'
export default {
  name:"revenue-analysis",
  components:{
    'tab-header':() => import('@/components/tab-header.vue'),
    'time-select':() => import('@/components/time-select'),
    'analysis-chart':() => import('./revenue/analysis-chart'),
    'synchronize-time':() => import('@/components/synchronize-time'),
    'revenue-detail':() => import('./revenue/revenue-detail'),
    'daqi-copyright':() => import('@/components/daqi-copyright')
  },
  data(){
    return {
      revenueSummary:null,
      //时间参数
      timeParams:{},
      //走势图参数
      chartParams:null
    }
  },
  computed:{
    ...mapState(['boss','userStatus'])
  },
  watch:{
    timeParams:{
      handler(newVal,oldVal){
        if(! newVal){
          return
        }
        if(! ! oldVal && newVal.startTime === oldVal.startTime){
          return
        }
        this.getRevenueByTime()
      },
      deep:true,
      immediate:true,
    },
    'userStatus.curs_node_id_2':{
      deep:true,
      handler:'getRevenueByTime'
    }
  },
  created () {
    this.$store.commit("updateLoadnum",4)
  },
  methods:{
    changeTabPage(index){
      this.$emit("changeTab",index)
      this.currentTab = index
    },
    /*时间切换*/
    timeChange(params){
      this.timeParams = params
    },
    /*切换营业走势图*/
    changeChartDate(chartParams){
      console.log(chartParams)
      this.chartParams = chartParams
    },
    /*营业分析*/
    getRevenueByTime(){
      let {timeParams:{startTime,endTime}} = this
      let params = {
        startTime,
        endTime,
      }
      table.getRevenueByTime(params).then(res =>{
        this.revenueSummary = res.data
      }).catch(err =>{
        console.log(err.info)
      })
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>


</style>
